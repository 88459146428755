import './assets/main.less'

import Vue from 'vue'

import App from './App.vue'
import router from './router'

import Vant from 'vant';
import Storage from 'vue-ls';
import Vconsole from 'vconsole';
import 'amfe-flexible'
import "@/assets/font/icon.css"
import 'vant/lib/index.css';

process.env.NODE_ENV === 'development' && new Vconsole();

const options = {
    name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
    storage: 'local', // 存储名称: session, local, memory
};

Vue.use(router);
Vue.use(Vant);

Vue.use(Storage, options);


router.beforeEach((to, from, next) => {
    window.scroll(0, 0);

    if (to.meta.title) {
        document.title = to.meta.title;
    }

    if (to.name === 'login' && Vue.ls.get('ACCESS_TOKEN')) {
        window.location.replace('/home');
    } else if (to.meta.needToken && !Vue.ls.get('ACCESS_TOKEN')) {
        window.location.replace(`/login`);
    } else {
        next();
    }
});


new Vue({
    router,
    render: h => h(App)
}).$mount('#app');