import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'index',
            redirect: '/login',
            component: () => import('../views/LoginView.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('../views/LoginView.vue'),
            meta: {title: '登录注册', needToken: false}
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('../views/HomeView.vue'),
            meta: {title: '首页', needToken: true}

        },
        {
            path: '/apply',
            name: 'apply',
            component: () => import('../views/ApplyView.vue'),
            meta: {title: '认证', needToken: true}

        },
        {
            path: '/status',
            name: 'ApplyStatus',
            component: () => import('../views/StatusView.vue'),
            meta: {title: '认证状态', needToken: true}
        },
        {
            name: 'storeList',
            path: '/storeList',
            component: () => import('@/views/StoreList.vue'),
            meta: {title: '药房列表', needToken: true, showContact: true}
        },
        {
            name: 'TransferDetail',
            path: '/transferDetail',
            component: () => import('@/views/TransferDetail.vue'),
            meta: {title: '收支明细'}
        },
        {
            name: 'TransferRecord',
            path: '/transferRecord',
            component: () => import('@/views/TransferRecord.vue'),
            meta: {title: '余额'}
        },
        {
            name: 'ChangePhone',
            path: '/changePhone',
            component: () => import('@/views/ChangePhone.vue'),
            meta: {title: '修改手机号'}
        },
    ]
})

export default router
